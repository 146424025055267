<template>
    <header class="app-header">
        <div class="container">
            <BNavbar toggleable="lg">
                <BNavbarBrand @click="redirect" exact class="d-flex">
                    <img src="@/assets/svg/logos/logo.svg" alt="logo" width="130px" height="51px" />
                </BNavbarBrand>
                <BNavbarToggle v-if="showLinks" target="nav-collapse" />
                <BCollapse id="nav-collapse" is-nav>
                    <BNavbarNav class="ms-auto"> </BNavbarNav>
                </BCollapse>
            </BNavbar>
        </div>
    </header>
</template>

<script lang="ts">
    import { dashboardRedirect } from "@/util/dashboardRedirect";

    export default {
        name: "HeaderApp",
        props: {
            authorized: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            showLinks(): boolean {
                if (!this.authorized) return true;

                const routeName = typeof this.$route.name === "string" ? this.$route.name : "home";
                return ![
                    "extension",
                    "thank-you",
                    "business-extension",
                    "registration",
                    "1click-login",
                    "login",
                ].includes(routeName);
            },
            extensionUrl(): string {
                return "/external-dashboard";
            },
        },
        methods: {
            redirect() {
                dashboardRedirect();
            },
        },
    };
</script>
