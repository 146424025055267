import { FIVE_DAYS_IN_MS, THREE_MONTHS_IN_MS } from "@/constants/boir.ts";

export const cookieNames = Object.freeze({
    ingestId: "ingest_id",
    redtrackClickId: "rtkclkid",
    redtrackCompanyId: "rtkcmpid",
});

export const readCookie = (key: string): string | null => {
    const cookies = document.cookie.split(";");
    const cookie = cookies.find((cookie) => cookie.trim().startsWith(`${key}=`));

    if (!cookie) {
        return null;
    }

    return decodeURIComponent(
        cookie
            .trim()
            .substring(key.length + 1)
            .replace(/\+/g, " "),
    );
};

export const storeCookie = (key: string, value: string, timeout: number, domain?: string, sameSite?: string): void => {
    const date: Date = new Date();
    date.setTime(date.getTime() + timeout);

    document.cookie = `${key}=${
        encodeURIComponent(value) + (timeout ? "; Expires=" + date.toUTCString() : "")
    }; Path=/; ${domain ? `Domain=${domain};` : ""} SameSite=${sameSite ?? "Lax"}; host-only-flag=false`;
};

export const getCookiesFromQs = () => {
    const url = new URL(window.location.href);

    const trackingCookies = [
        {
            name: cookieNames.redtrackClickId,
            expires: THREE_MONTHS_IN_MS,
        },
        {
            name: cookieNames.redtrackCompanyId,
            expires: THREE_MONTHS_IN_MS,
        },
        {
            name: cookieNames.ingestId,
            expires: FIVE_DAYS_IN_MS,
        },
    ] as const;

    trackingCookies.forEach(({ name, expires }) => {
        const value = url.searchParams.get(name);

        if (!value) {
            return;
        }

        storeCookie(name, value, expires);
    });
};
