import { provide, inject } from "vue";
import posthog, { PostHog } from "posthog-js";
import { cookieNames, readCookie } from "@/util/cookies.ts";

const PostHogSymbol = Symbol("PostHog");

export function usePostHogProvider() {
    const currentUrl = new URL(window.location.href);
    const sessionID = currentUrl.searchParams.get("session_id");

    const posthogInstance = posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_URL,
        ui_host: "https://us.posthog.com",
        person_profiles: "identified_only",
        ...(sessionID
            ? {
                  bootstrap: {
                      sessionID,
                  },
              }
            : {}),
        loaded: (phInstance: PostHog) => {
            const ingestId = readCookie(cookieNames.ingestId);
            phInstance.identify(ingestId ?? undefined);
        },
    });

    provide(PostHogSymbol, posthogInstance);

    return posthogInstance;
}

export function usePostHog() {
    const posthogInstance = inject(PostHogSymbol);
    if (!posthogInstance) {
        console.log("PostHog has not been provided");
    }

    return posthogInstance;
}
