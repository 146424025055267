import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore(
    "user",
    () => {
        const userUid = ref("");
        const userFirstName = ref("");
        const userLastName = ref("");
        const userEmail = ref("");

        const reset = () => {
            userUid.value = "";
            userFirstName.value = "";
            userLastName.value = "";
            userEmail.value = "";
        };

        const setUserData = (id: string, firstName: string, lastName: string, email: string) => {
            userUid.value = id;
            userFirstName.value = firstName;
            userLastName.value = lastName;
            userEmail.value = email;
        };

        return {
            userUid,
            userFirstName,
            userLastName,
            userEmail,
            setUserData,
            reset,
        };
    },
    { persist: true },
);
