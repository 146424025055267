import type { Agent } from "@fingerprintjs/fingerprintjs";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { LoginRequest } from "@/models/login-request";
import authService from "@/services/auth-service";
import { resetUserData } from "./reset-stores";
import { LoginResponseTokenTypeEnum } from "@/models/login-response";
import { UserProfile } from "@/models/user-profile";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { getSHA1 } from "@/util/crypto";
import { useUserStore } from "./userStore";
import { PostHog } from "posthog-js";
import * as Sentry from "@sentry/vue";

export const useAuthStore = defineStore("auth", () => {
    const deviceId = ref("");
    const authToken = ref("");
    const refToken = ref("");

    const hasAuthToken = computed(() => !!authToken.value);

    const setRefreshToken = (token: string) => {
        refToken.value = token;
    };

    const setPosthogSession = (setForAnotherUser: boolean, user?: UserProfile, posthogInstance?: PostHog) => {
        if (!posthogInstance || !user || setForAnotherUser) {
            return;
        }
        
        if (posthogInstance.get_distinct_id() !== user.id) {
            posthogInstance.identify(user.id, {
                id: user.id,
                email: user.email,
                name: `${user.first_name} ${user.last_name}`
            });
        }
    };

    const setAuthData = (token: string, user?: UserProfile, posthogInstance?: PostHog) => {
        const userStore = useUserStore();
        authToken.value = token;
        const userId = user ? user.id : "";
        const userFirstName = user ? user.first_name : "";
        const userLastName = user ? user.last_name : "";
        const userEmail = user ? user.email : "";

        const isAnotherUser = userStore.userUid !== userId;
        const prevUserId = userStore.userUid;
        setPosthogSession(isAnotherUser, user, posthogInstance);

        if (isAnotherUser) {
            resetUserData();
            userStore.setUserData(userId, userFirstName, userLastName, userEmail);

            // If prevUserId is empty we don't want to capture event, because user was not authenticated
            if (!!prevUserId && !!userId) {
                Sentry.captureException(`A user account was changed! Previous user id: ${prevUserId}, new user id: ${userId}`);
            }
        }
    };

    // TODO: remove login functionality once we are ready
    const login = async (params: LoginRequest, posthogInstance?: PostHog) => {
        const loginResponse = await authService.login(params);
        const { profile, token_type, token } = loginResponse;

        if (token_type === LoginResponseTokenTypeEnum.Redirect) {
            setRefreshToken(token);
            await refreshToken(token, posthogInstance);

            return;
        }

        if (token) {
            setAuthData(token, profile, posthogInstance);
        }
    };

    const refreshToken = async (token?: string, posthogInstance?: PostHog) => {
        try {
            const userData = await authService.refreshToken(token);
            if (userData && userData.jwt_token) {
                setAuthData(userData.jwt_token, userData.profile, posthogInstance);
            } else {
                setAuthData("");
            }
        } catch (e) {
            setAuthData("");
        }
    };

    const updateDeviceId = async () => {
        await FingerprintJS.load().then(async (agent: Agent) => {
            const { visitorId } = await agent.get();
            const fpSHA1 = await getSHA1(visitorId);

            setDeviceId(fpSHA1);
        });
    };

    const setDeviceId = (id: string) => {
        deviceId.value = id;
    };

    const getDeviceId = async () => {
        if (!deviceId.value) {
            await updateDeviceId();
        }

        return deviceId.value;
    };

    return {
        deviceId,
        authToken,
        refToken,
        hasAuthToken,
        setRefreshToken,
        setAuthData,
        login,
        refreshToken,
        updateDeviceId,
        setDeviceId,
        getDeviceId,
    };
});
