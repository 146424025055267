import type { RouteRecordExt } from "./route-record-ext";

const defaultPath = "/";
const externalDashboardPath = "/external-dashboard";
const formName = "form";

const routes: RouteRecordExt[] = [
    {
        path: defaultPath,
        name: "default",
        component: () => loadComp("Home.vue"),
        title: "Home",
        meta: { showInMenu: true },
    },
    {
        path: "/401-forbidden",
        name: "forbidden",
        component: () => loadComp("errors/Error401Forbidden.vue"),
        title: "401 Forbidden",
    },
    {
        path: "/404-not-found",
        name: "not-found",
        component: () => loadComp("errors/Error404NotFound.vue"),
        title: "404 Page Not Found",
    },
    {
        path: externalDashboardPath,
        name: "external-dashboard",
        component: () => loadComp("errors/ExternalDashboard.vue"),
        title: "Temporary error page when we need to redirect to external dashboard",
    },
    { path: "/login/:step?", name: "login", component: () => loadComp("auth/Login.vue") },
    { path: "/registration/:step?", name: "registration", component: () => loadComp("auth/Register.vue") },
    { path: "/forgot", name: "forgot", component: () => loadComp("auth/Forgot.vue") },
    { path: "/form/:step?", name: formName, component: () => loadComp("Form.vue") },
    { path: "/:catchAll(.*)", redirect: { name: "not-found" } },
];

async function loadComp(path: string) {
    const comps = import.meta.glob("../views/**/*.vue");
    const match: () => Promise<any> = comps[`../views/${path}`];

    return (await match()).default;
}

export { defaultPath, externalDashboardPath, formName, routes, type RouteRecordExt };
