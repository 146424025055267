import { defineStore } from "pinia";
import type { CompanyDetailsViewModel } from "@/models/company-details";
import type { BusinessTypeViewModel } from "@/models/business-type";
import type { EntityViewModel } from "@/models/entity";
import type { CompanyApplicantViewModel } from "@/models/company-applicant";
import type { CompanyApplicantDocumentViewModel } from "@/models/company-applicant-document";
import type { BeneficialOwnerViewModel } from "@/models/beneficial-owner";
import type { BeneficialOwnerDocumentViewModel } from "@/models/beneficial-owner-document";
import type { FormViewModel } from "@/models/form";
import type { BeneficialOwner } from "@/models/svc/beneficial-owner";
import type { IdDocument } from "@/models/svc/id-document";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";
import { IdTypes } from "@/constants/document-types";
import { StateCountries } from "@/constants/countries";
import { ApplicantTypes } from "@/constants/applicant-types";
import { IdentificationTypes } from "@/constants/identification-types";
import { Form } from "@/models/svc/form";
import formService from "@/services/form-service";
import { ApplicantDocumentImage } from "@/models/applicant-document-image";
import { AddressComponent } from "@/models/address-component";
import { InformationComponent } from "@/models/information-component";
import { formatDateToIsoString, inputDateFormat } from "@/util/helper";
import { PaymentStatusType } from "@/models/svc/payment-status-type";
import { useGtm } from "@gtm-support/vue-gtm";
import { BoirFormEvent, booleanToEvent } from "@/constants/tag-manager.ts";
import { PaymentDetailsViewModel } from "@/models/payment-details";

interface StoreState {
    form: FormViewModel;
    subStep: number | null;
    formId: string;
    deviceType: string;
    lastModifiedForm: Form | undefined;
    lastModifiedStep: number | undefined;
    failedBoIds: string[];
    failedBoImageIds: string[];
}

export const useStore = defineStore({
    id: "store",
    state: (): StoreState => {
        return {
            form: {
                step4: {},
                step6: {
                    documents: [],
                    images: [],
                },
            },
            subStep: null,
            deviceType: "Unknown",
            formId: "",
            lastModifiedForm: undefined,
            lastModifiedStep: undefined,
            failedBoIds: [],
            failedBoImageIds: [],
        };
    },
    actions: {
        setSubStep(subStep: number | null) {
            this.subStep = subStep;
        },

        saveFormId(formId: string) {
            this.formId = formId;
        },

        addFailedBoIds(boId: string) {
            this.failedBoIds.push(boId);
        },

        removeFailedBoIds(boId: string) {
            this.failedBoIds = this.failedBoIds.filter((f) => f !== boId);
        },

        addFailedBoImageIds(boId: string) {
            this.failedBoImageIds.push(boId);
        },

        removeFailedBoImageIds(boId: string) {
            this.failedBoImageIds = this.failedBoImageIds.filter((f) => f !== boId);
        },

        formIsPaid(form?: Form) {
            return !!form && form.paymentStatus === PaymentStatusType.Success;
        },

        formIsRefunded(form?: Form) {
            return !!form && form.paymentStatus === PaymentStatusType.Refund;
        },

        saveStep1(data: CompanyDetailsViewModel) {
            this.form.step1 = data;
        },

        async getStep1() {
            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                userId: useUserStore().userUid,
                step: 2,
                legalCompanyName: this.form.step1?.legalCompanyName,
                hasAlternateNames: this.form.step1?.hasAlternateNames,
                isForeignPooledInvestmentVehicle: this.form.step1?.isForeignPooledInvestmentVehicle,
                alternateCompanyNames: this.form.step1?.hasAlternateNames ? this.form.step1?.alternateCompanyNames : [],
                createdBeforeDate: this.form.step1?.createdBeforeDate,
            } as CompanyDetailsViewModel;
        },

        fetchStep1() {
            const companyDetails = {
                userId: this.lastModifiedForm?.userId || "",
                legalCompanyName: this.lastModifiedForm?.businessName || "",
                hasAlternateNames:
                    !!this.lastModifiedForm?.businessAltNames && this.lastModifiedForm?.businessAltNames.length > 0,
                isForeignPooledInvestmentVehicle: this.lastModifiedForm?.isForeignPooledInvestmentVehicle || false,
                alternateCompanyNames: this.lastModifiedForm?.businessAltNames || [],
                createdBeforeDate: this.lastModifiedForm?.isCreatedBfJan2024 || false,
            } as CompanyDetailsViewModel;

            this.saveStep1(companyDetails);
        },

        saveStep2(data: BusinessTypeViewModel) {
            this.form.step2 = data;
        },

        async getStep2() {
            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                step: 3,
                usCompany: this.form.step2?.usCompany,
                stateFormed: this.form.step2?.usCompany ? this.form.step2?.stateFormed : null,
                countryFormed: this.form.step2?.usCompany ? null : this.form.step2?.countryFormed,
                registrationState: this.form.step2?.usCompany ? null : this.form.step2?.registrationState,
            } as BusinessTypeViewModel;
        },

        fetchStep2() {
            const businessType = {
                usCompany: this.lastModifiedForm?.isUsCreatedBased || false,
                stateFormed: this.lastModifiedForm?.stateFormed || "",
                countryFormed: this.lastModifiedForm?.countryFormed || "",
                registrationState: this.lastModifiedForm?.intlJurisdictionFormed || "",
            } as BusinessTypeViewModel;

            this.saveStep2(businessType);
        },

        saveStep3(data: EntityViewModel) {
            this.form.step3 = data;
        },

        getStep3StreetAddress() {
            return this.form.step2?.usCompany || (!this.form.step2?.usCompany && this.subStep === 1)
                ? this.form.step3?.streetAddress
                : null;
        },

        getStep3StreetAddress2() {
            return this.form.step2?.usCompany || (!this.form.step2?.usCompany && this.subStep === 1)
                ? this.form.step3?.streetAddress2
                : null;
        },

        getStep3City() {
            return this.form.step2?.usCompany || (!this.form.step2?.usCompany && this.subStep === 1)
                ? this.form.step3?.city
                : null;
        },

        getStep3State() {
            return this.form.step2?.usCompany || (!this.form.step2?.usCompany && this.subStep === 1)
                ? this.form.step3?.state
                : null;
        },

        getStep3Zip() {
            return this.form.step2?.usCompany || (!this.form.step2?.usCompany && this.subStep === 1)
                ? this.form.step3?.zip
                : null;
        },

        getStep3AddressComponent() {
            return {
                address_1: this.getStep3StreetAddress(),
                address_2: this.getStep3StreetAddress2(),
                city: this.getStep3City(),
                state: this.getStep3State(),
                zip: this.getStep3Zip(),
            } as AddressComponent;
        },

        getStep3AddressComponentReview() {
            return {
                address_1: this.form.step3?.streetAddress,
                address_2: this.form.step3?.streetAddress2,
                city: this.form.step3?.city,
                state: this.form.step3?.state,
                zip: this.form.step3?.zip,
            } as AddressComponent;
        },

        async getStep3(subStep?: number) {
            const hasSubStep = !!subStep && subStep === 1;

            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                step: hasSubStep ? 3 : 4,
                subStep: hasSubStep ? 1 : undefined,
                domesticTaxIdType: this.form.step2?.usCompany ? this.form.step3?.domesticTaxIdType : null,
                foreignTaxIdType: this.form.step2?.usCompany ? null : this.form.step3?.foreignTaxIdType,
                taxCountry:
                    this.form.step2?.usCompany || this.form.step3?.foreignTaxIdType === IdTypes.ForeignEIN
                        ? StateCountries.US
                        : this.form.step3?.taxCountry,
                taxId: this.form.step3?.taxId,
                streetAddress: this.getStep3StreetAddress(),
                streetAddress2: this.getStep3StreetAddress2(),
                city: this.getStep3City(),
                state: this.getStep3State(),
                zip: this.getStep3Zip(),
            } as EntityViewModel;
        },

        fetchStep3() {
            const entity = {
                domesticTaxIdType: this.lastModifiedForm?.domesticTaxIdType || "",
                foreignTaxIdType: this.lastModifiedForm?.foreignTaxIdType || "",
                taxCountry: this.lastModifiedForm?.countryTaxIdIssued || "",
                taxId: this.lastModifiedForm?.taxId || "",
                streetAddress: this.lastModifiedForm?.bizAddress?.address1 || "",
                streetAddress2: this.lastModifiedForm?.bizAddress?.address2 || "",
                city: this.lastModifiedForm?.bizAddress?.city || "",
                state: this.lastModifiedForm?.bizAddress?.state || "",
                zip: this.lastModifiedForm?.bizAddress?.zip || "",
            } as EntityViewModel;

            this.saveStep3(entity);
        },

        saveStep4Applicant(data: CompanyApplicantViewModel) {
            this.form.step4.applicant = data;
        },

        async getStep4Applicant(subStep?: number) {
            const hasSubStep = !!subStep && subStep >= 1 && subStep <= 5;

            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                step: hasSubStep ? 4 : 5,
                subStep: hasSubStep ? subStep : undefined,
                isApplicantBeneficialOwner:
                    this.form.step1?.isForeignPooledInvestmentVehicle || this.form.step1?.createdBeforeDate
                        ? this.form.step4?.applicant?.isApplicantBeneficialOwner
                        : this.form.step4?.applicant?.applicantType === ApplicantTypes.Both
                          ? true
                          : false,
                applicantType:
                    !this.form.step1?.isForeignPooledInvestmentVehicle && !this.form.step1?.createdBeforeDate
                        ? this.form.step4?.applicant?.applicantType
                        : this.form.step4?.applicant?.isApplicantBeneficialOwner
                          ? ApplicantTypes.BeneficialOnly
                          : ApplicantTypes.Neither,
                firstName: this.form.step4?.applicant?.firstName,
                middleName: this.form.step4?.applicant?.middleName,
                lastName: this.form.step4?.applicant?.lastName,
                birthDate: this.subStep === null ? null : formatDateToIsoString(this.form.step4?.applicant?.birthDate),
                addressType: this.form.step4?.applicant?.addressType,
                streetAddress: this.form.step4?.applicant?.streetAddress,
                streetAddress2: this.form.step4?.applicant?.streetAddress2,
                city: this.form.step4?.applicant?.city,
                state: this.form.step4?.applicant?.state,
                zip: this.form.step4?.applicant?.zip,
                country: this.form.step4?.applicant?.country,
            } as CompanyApplicantViewModel;
        },

        getStep4ApplicantAddressComponent() {
            return {
                address_1: this.form.step4?.applicant?.streetAddress,
                address_2: this.form.step4?.applicant?.streetAddress2,
                address_type: this.form.step4?.applicant?.addressType,
                city: this.form.step4?.applicant?.city,
                state: this.form.step4?.applicant?.state,
                zip: this.form.step4?.applicant?.zip,
                country: this.form.step4?.applicant?.country,
            } as AddressComponent;
        },

        getStep4ApplicantInformationComponent() {
            return {
                firstName: this.form.step4?.applicant?.firstName,
                namePrefix: this.form.step4?.applicant?.middleName,
                lastName: this.form.step4?.applicant?.lastName,
                dob: inputDateFormat(this.form.step4?.applicant?.birthDate),
            } as InformationComponent;
        },

        async fetchStep4Applicant() {
            try {
                const applicant = await formService.getCompanyApplicant(this.formId);
                const companyApplicant = {
                    isApplicantBeneficialOwner: applicant.isApplicantBeneficialOwner || false,
                    applicantType: applicant.applicantType || "",
                    firstName: applicant.personDetails?.firstName || "",
                    middleName: applicant.personDetails?.middleName || "",
                    lastName: applicant.personDetails?.lastName || "",
                    birthDate: applicant.personDetails?.dob || "",
                    addressType: applicant.personDetails?.address?.addressType || "",
                    streetAddress: applicant.personDetails?.address?.address1 || "",
                    streetAddress2: applicant.personDetails?.address?.address2 || "",
                    city: applicant.personDetails?.address?.city || "",
                    state: applicant.personDetails?.address?.state || "",
                    zip: applicant.personDetails?.address?.zip || "",
                    country: applicant.personDetails?.address?.country || "",
                } as CompanyApplicantViewModel;

                this.saveStep4Applicant(companyApplicant);
            } catch (error) {
                // used to be empty
            }
        },

        saveStep4Document(data: CompanyApplicantDocumentViewModel) {
            this.form.step4.document = data;
        },

        async getStep4Document() {
            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                step: 4,
                subStep: 3,
                applicantDocumentType: this.form.step4?.document?.applicantDocumentType,
                identificationNumber: this.form.step4?.document?.identificationNumber,
                stateOfIssue:
                    this.form.step4?.document?.applicantDocumentType === IdentificationTypes.UsDriverLicense ||
                    this.form.step4?.document?.applicantDocumentType === IdentificationTypes.StateId
                        ? this.form.step4?.document?.stateOfIssue
                        : null,
                expirationDate: formatDateToIsoString(this.form.step4?.document?.expirationDate),
                countryOfIssue:
                    this.form.step4?.document?.applicantDocumentType === IdentificationTypes.ForeignPassport
                        ? this.form.step4?.document?.countryOfIssue
                        : StateCountries.US,
            } as CompanyApplicantDocumentViewModel;
        },

        saveStep4Image(data: ApplicantDocumentImage) {
            this.form.step4.image = data;
        },

        async getStep4Image() {
            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                step: 5,
                userId: useUserStore().userUid,
                document: this.form.step4?.image?.document,
                filename: this.form.step4?.image?.document?.name,
            } as ApplicantDocumentImage;
        },

        async fetchStep4DocumentAndFile() {
            try {
                const document = await formService.getCompanyApplicantDocument(this.formId);
                const applicantDocument = {
                    applicantDocumentType: document.type || "",
                    identificationNumber: document.number || "",
                    stateOfIssue: document.stateIssued || "",
                    expirationDate: document.expirationDate || "",
                    countryOfIssue: document.countryIssued || "",
                } as CompanyApplicantDocumentViewModel;

                const applicantFile = {
                    document: null,
                    filename: document.filename,
                } as ApplicantDocumentImage;

                this.saveStep4Document(applicantDocument);
                this.saveStep4Image(applicantFile);
            } catch (error) {
                // used to be empty
            }
        },

        saveStep4Filer(data: CompanyApplicantViewModel) {
            this.form.step4.filer = data;
        },

        async getStep4Filer() {
            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                step: 4,
                subStep: 5,
                firstName: this.form.step4?.filer?.firstName,
                middleName: this.form.step4?.filer?.middleName,
                lastName: this.form.step4?.filer?.lastName,
                birthDate: formatDateToIsoString(this.form.step4?.filer?.birthDate),
                addressType: this.form.step4?.filer?.addressType,
                streetAddress: this.form.step4?.filer?.streetAddress,
                streetAddress2: this.form.step4?.filer?.streetAddress2,
                city: this.form.step4?.filer?.city,
                state: this.form.step4?.filer?.state,
                zip: this.form.step4?.filer?.zip,
                country: this.form.step4?.filer?.country,
            } as CompanyApplicantViewModel;
        },

        getStep4FilerAddressComponent() {
            return {
                address_1: this.form.step4?.filer?.streetAddress,
                address_2: this.form.step4?.filer?.streetAddress2,
                address_type: this.form.step4?.filer?.addressType,
                city: this.form.step4?.filer?.city,
                state: this.form.step4?.filer?.state,
                zip: this.form.step4?.filer?.zip,
                country: this.form.step4?.filer?.country,
            } as AddressComponent;
        },

        getStep4FilerInformationComponent() {
            return {
                firstName: this.form.step4?.filer?.firstName,
                namePrefix: this.form.step4?.filer?.middleName,
                lastName: this.form.step4?.filer?.lastName,
                dob: inputDateFormat(this.form.step4?.filer?.birthDate),
            } as InformationComponent;
        },

        async fetchStep4Filer() {
            try {
                const filer = await formService.getFiler(this.formId);
                const applicantFiler = {
                    firstName: filer.personDetails?.firstName || "",
                    middleName: filer.personDetails?.middleName || "",
                    lastName: filer.personDetails?.lastName || "",
                    birthDate: filer.personDetails?.dob || "",
                    addressType: filer.personDetails?.address?.addressType || "",
                    streetAddress: filer.personDetails?.address?.address1 || "",
                    streetAddress2: filer.personDetails?.address?.address2 || "",
                    city: filer.personDetails?.address?.city || "",
                    state: filer.personDetails?.address?.state || "",
                    zip: filer.personDetails?.address?.zip || "",
                    country: filer.personDetails?.address?.country || "",
                } as CompanyApplicantViewModel;

                this.saveStep4Filer(applicantFiler);
            } catch (error) {
                // used to be empty
            }
        },

        saveStep5(data: BeneficialOwnerViewModel[]) {
            this.form.step5 = data;
        },

        async getStep5(data: BeneficialOwnerViewModel[] | BeneficialOwner[]) {
            return (await Promise.all(
                data.map(async (step5) => ({
                    formId: this.formId,
                    deviceId: await useAuthStore().getDeviceId(),
                    step: 6,
                    isMinor: (step5 as BeneficialOwnerViewModel)?.isMinor ?? (step5 as BeneficialOwner).isMinorOrChild,
                    firstName:
                        (step5 as BeneficialOwnerViewModel)?.firstName ??
                        (step5 as BeneficialOwner).personDetails?.firstName,
                    middleName:
                        (step5 as BeneficialOwnerViewModel)?.middleName ??
                        (step5 as BeneficialOwner).personDetails?.middleName,
                    lastName:
                        (step5 as BeneficialOwnerViewModel)?.lastName ??
                        (step5 as BeneficialOwner).personDetails?.lastName,
                    birthDate: formatDateToIsoString(
                        (step5 as BeneficialOwnerViewModel)?.birthDate ?? (step5 as BeneficialOwner).personDetails?.dob,
                    ),
                    addressType:
                        (step5 as BeneficialOwnerViewModel)?.addressType ??
                        (step5 as BeneficialOwner).personDetails?.address?.addressType,
                    streetAddress:
                        (step5 as BeneficialOwnerViewModel)?.streetAddress ??
                        (step5 as BeneficialOwner).personDetails?.address?.address1,
                    streetAddress2:
                        (step5 as BeneficialOwnerViewModel)?.streetAddress2 ??
                        (step5 as BeneficialOwner).personDetails?.address?.address2,
                    city:
                        (step5 as BeneficialOwnerViewModel)?.city ??
                        (step5 as BeneficialOwner).personDetails?.address?.city,
                    state:
                        (step5 as BeneficialOwnerViewModel)?.state ??
                        (step5 as BeneficialOwner).personDetails?.address?.state,
                    zip:
                        (step5 as BeneficialOwnerViewModel)?.zip ??
                        (step5 as BeneficialOwner).personDetails?.address?.zip,
                    country:
                        (step5 as BeneficialOwnerViewModel)?.country ??
                        (step5 as BeneficialOwner).personDetails?.address?.country,
                    beneficialOwnerId: step5.beneficialOwnerId,
                })),
            )) as BeneficialOwnerViewModel[];
        },

        getStep5AddressComponent(index: number) {
            return {
                address_1: this.form.step5?.[index]?.streetAddress,
                address_2: this.form.step5?.[index]?.streetAddress2,
                address_type: this.form.step5?.[index]?.addressType,
                city: this.form.step5?.[index]?.city,
                state: this.form.step5?.[index]?.state,
                zip: this.form.step5?.[index]?.zip,
                country: this.form.step5?.[index]?.country,
            } as AddressComponent;
        },

        getStep5InformationComponent(index: number) {
            return {
                firstName: this.form.step5?.[index]?.firstName,
                namePrefix: this.form.step5?.[index]?.middleName,
                lastName: this.form.step5?.[index]?.lastName,
                dob: inputDateFormat(this.form.step5?.[index]?.birthDate),
            } as InformationComponent;
        },

        async fetchStep5() {
            try {
                const beneficialOwnerList = await formService.getBeneficialOwners(this.formId);
                const beneficialOwners = beneficialOwnerList.beneficialOwners?.map((owner) => ({
                    isMinor: !!owner.isMinorOrChild,
                    firstName: owner.personDetails?.firstName || "",
                    middleName: owner.personDetails?.middleName || "",
                    lastName: owner.personDetails?.lastName || "",
                    birthDate: owner.personDetails?.dob || "",
                    addressType: owner.personDetails?.address?.addressType || "",
                    streetAddress: owner.personDetails?.address?.address1 || "",
                    streetAddress2: owner.personDetails?.address?.address2 || "",
                    city: owner.personDetails?.address?.city || "",
                    state: owner.personDetails?.address?.state || "",
                    zip: owner.personDetails?.address?.zip || "",
                    country: owner.personDetails?.address?.country || "",
                    beneficialOwnerId: owner.beneficialOwnerId || "",
                })) as BeneficialOwnerViewModel[];

                this.saveStep5(beneficialOwners);
            } catch (error) {
                // used to be empty
            }
        },

        saveStep6Document(data: BeneficialOwnerDocumentViewModel) {
            const step6Buf = this.form.step6!.documents!.filter((f) => f.beneficialOwnerId !== data.beneficialOwnerId);
            step6Buf.push(data);
            this.form.step6!.documents! = step6Buf;
        },

        async getStep6Document(beneficialOwnerId: string) {
            const step6CurrentBo = this.form.step6?.documents?.find((f) => f.beneficialOwnerId === beneficialOwnerId);

            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                beneficialOwnerId: step6CurrentBo?.beneficialOwnerId,
                beneficialOwnerDocumentType: step6CurrentBo?.beneficialOwnerDocumentType,
                identificationNumber: step6CurrentBo?.identificationNumber,
                stateOfIssue:
                    step6CurrentBo?.beneficialOwnerDocumentType === IdentificationTypes.UsDriverLicense ||
                    step6CurrentBo?.beneficialOwnerDocumentType === IdentificationTypes.StateId
                        ? step6CurrentBo?.stateOfIssue
                        : null,
                expirationDate: formatDateToIsoString(step6CurrentBo?.expirationDate),
                countryOfIssue:
                    step6CurrentBo?.beneficialOwnerDocumentType === IdentificationTypes.ForeignPassport
                        ? step6CurrentBo?.countryOfIssue
                        : StateCountries.US,
            } as BeneficialOwnerDocumentViewModel;
        },

        saveStep6Image(data: ApplicantDocumentImage) {
            const step6ImageBuf = this.form.step6!.images!.filter(
                (f) => f.beneficialOwnerId !== data.beneficialOwnerId,
            );
            step6ImageBuf.push(data);
            this.form.step6!.images! = step6ImageBuf;
        },

        async getStep6Image(beneficialOwnerId: string) {
            const step6ImageCurrentBo = this.form.step6?.images?.find((f) => f.beneficialOwnerId === beneficialOwnerId);

            return {
                formId: this.formId,
                deviceId: await useAuthStore().getDeviceId(),
                userId: useUserStore().userUid,
                beneficialOwnerId: step6ImageCurrentBo?.beneficialOwnerId,
                document: step6ImageCurrentBo?.document,
            } as ApplicantDocumentImage;
        },

        async fetchStep6() {
            const ownerIds = this.form.step5?.map((owner) => owner.beneficialOwnerId) || [];
            const tasks: Promise<IdDocument>[] = [];

            try {
                for (const ownerId of ownerIds) {
                    if (!ownerId) {
                        continue;
                    }

                    tasks.push(formService.getBeneficialOwnerDocument(this.formId, ownerId));
                }

                const results = await Promise.all(tasks);
                for (const result of results) {
                    const ownerDocument = {
                        beneficialOwnerId: result.beneficialOwnerId,
                        beneficialOwnerDocumentType: result.type,
                        identificationNumber: result.number,
                        stateOfIssue: result.stateIssued,
                        expirationDate: result.expirationDate,
                        countryOfIssue: result.countryIssued,
                    } as BeneficialOwnerDocumentViewModel;

                    const ownerFile = {
                        beneficialOwnerId: result.beneficialOwnerId,
                        filename: result.filename,
                    } as ApplicantDocumentImage;

                    this.saveStep6Document(ownerDocument);
                    this.saveStep6Image(ownerFile);
                }
            } catch (error) {
                // used to be empty
            }
        },

        removeStep6DetachedIds() {
            const ownerIds =
                this.form.step5?.filter((f) => !!f.beneficialOwnerId).map((owner) => owner.beneficialOwnerId!) || [];

            this.form.step6!.documents = this.form.step6!.documents!.filter((f) =>
                ownerIds.some((id) => id === f.beneficialOwnerId),
            );
            this.form.step6!.images = this.form.step6!.images!.filter((f) =>
                ownerIds.some((id) => id === f.beneficialOwnerId),
            );

            this.failedBoIds = this.failedBoIds.filter((f) => ownerIds.some((id) => id === f));
            this.failedBoImageIds = this.failedBoImageIds.filter((f) => ownerIds.some((id) => id === f));
        },

        saveStep8(data: PaymentDetailsViewModel) {
            this.form.step8 = data;
        },

        getDeviceInfo() {
            const userAgent = navigator.userAgent;
            let deviceType = "Unknown";

            if (/Mobi|Android/i.test(userAgent)) {
                deviceType = "Mobile";
            } else if (/iPad|Tablet/i.test(userAgent)) {
                deviceType = "Tablet";
            } else {
                deviceType = "Desktop";
            }

            this.deviceType = deviceType;
        },

        async getLastModifiedForm(formId: string) {
            try {
                this.lastModifiedForm = await formService.getForm(formId);
            } catch (error) {
                // used to be empty
            }
        },

        isLastModifiedFormLocked() {
            return !!this.lastModifiedForm?.isLocked || !this.lastModifiedForm?.isEditable;
        },

        async getLastModifiedStep() {
            if (!this.formId) {
                return;
            }

            const response = await formService.getFormMeta(this.formId);
            this.lastModifiedStep = response.metadata?.step;

            if (this.lastModifiedStep === 8 && this.formIsPaid(this.lastModifiedForm)) {
                this.lastModifiedStep = 10;
                return;
            }

            const subStepRange =
                !!response.metadata?.subStep && response.metadata.subStep >= 1 && response.metadata.subStep <= 5
                    ? response.metadata.subStep
                    : null;

            if (this.lastModifiedStep === 3) {
                this.setSubStep(!!subStepRange && subStepRange === 1 ? subStepRange : null);
            } else if (this.lastModifiedStep === 4) {
                this.setSubStep(subStepRange);
            } else if (this.lastModifiedStep === 6) {
                this.setSubStep(subStepRange);
            } else {
                this.setSubStep(null);
            }
        },

        setLastModifiedStep(step: number) {
            this.lastModifiedStep = step;
        },

        fetchAllBeforeStep2() {
            this.fetchStep1();
        },

        fetchAllBeforeStep3() {
            this.fetchStep1();
            this.fetchStep2();

            if (this.subStep === 1) {
                this.fetchStep3();
            }
        },

        async fetchAllBeforeStep4() {
            this.fetchStep1();
            this.fetchStep2();
            this.fetchStep3();

            if (this.subStep) {
                const subStepData = [this.fetchStep4Applicant()];
                if (this.subStep > 3) {
                    subStepData.push(this.fetchStep4DocumentAndFile());
                }

                if (this.subStep > 4) {
                    subStepData.push(this.fetchStep4Filer());
                }

                await Promise.all(subStepData);
            }
        },

        async fetchAllBeforeStep5() {
            this.fetchStep1();
            this.fetchStep2();
            this.fetchStep3();
            await Promise.all([this.fetchStep4Applicant(), this.fetchStep4DocumentAndFile(), this.fetchStep4Filer()]);
        },

        async fetchAllBeforeStep6() {
            this.fetchStep1();
            this.fetchStep2();
            this.fetchStep3();
            await Promise.all([
                this.fetchStep4Applicant(),
                this.fetchStep4DocumentAndFile(),
                this.fetchStep4Filer(),
                this.fetchStep5(),
            ]);
        },

        async fetchAll() {
            this.fetchStep1();
            this.fetchStep2();
            this.fetchStep3();
            await Promise.all([
                this.fetchStep4Applicant(),
                this.fetchStep4DocumentAndFile(),
                this.fetchStep4Filer(),
                this.fetchStep5(),
            ]);
            await this.fetchStep6();
        },

        async downloadFormData() {
            if (!this.lastModifiedStep) {
                return;
            }

            switch (this.lastModifiedStep) {
                case 2: {
                    this.fetchAllBeforeStep2();
                    break;
                }
                case 3: {
                    this.fetchAllBeforeStep3();
                    break;
                }
                case 4: {
                    await this.fetchAllBeforeStep4();
                    break;
                }
                case 5: {
                    await this.fetchAllBeforeStep5();
                    break;
                }
                case 6: {
                    await this.fetchAllBeforeStep6();
                    break;
                }
                case 7: {
                    await this.fetchAll();
                    break;
                }
                case 8: {
                    await this.fetchAll();
                    break;
                }
                default: {
                    // if lastModifiedStep is present this data should be available
                    this.fetchAllBeforeStep3();
                    break;
                }
            }
        },

        reset() {
            this.form = {
                step4: {},
                step6: {
                    documents: [],
                    images: [],
                },
            };

            this.subStep = null;
            this.deviceType = "Unknown";
            this.formId = "";
            this.lastModifiedForm = undefined;
            this.lastModifiedStep = undefined;
            this.failedBoIds = [];
            this.failedBoImageIds = [];
        },

        trackForm(step: number) {
            const ein_ssn_itin = this.form.step3?.domesticTaxIdType === "TAX_ID_TYPE_EIN" ? "ein" : "ssn";

            const isBeneficialOwnerOrApplicantDictionary = {
                [ApplicantTypes.Both]: "owner_and_applicant",
                [ApplicantTypes.BeneficialOnly]: "owner",
                [ApplicantTypes.ApplicantOnly]: "applicant",
                [ApplicantTypes.Neither]: "neither",
            };

            const ownerFromStore = this.form.step4?.applicant?.applicantType;
            const is_beneficial_owner_or_applicant = ownerFromStore
                ? isBeneficialOwnerOrApplicantDictionary[ownerFromStore]
                : null;
            const is_beneficial_owner = booleanToEvent(this.form.step4?.applicant?.isApplicantBeneficialOwner);
            const step_4_data = this.form.step1?.createdBeforeDate
                ? { is_beneficial_owner }
                : { is_beneficial_owner_or_applicant };

            useGtm()?.push({
                event: BoirFormEvent,
                step,
                step_1_data: {
                    created_before_2024: booleanToEvent(this.form.step1?.createdBeforeDate),
                    foreign_pooled_investment_vehicle: booleanToEvent(
                        this.form.step1?.isForeignPooledInvestmentVehicle,
                    ),
                },
                ...(step > 1
                    ? {
                          step_2_data: {
                              based_in_us: booleanToEvent(this.form.step2?.usCompany),
                          },
                      }
                    : {}),
                ...(step > 2
                    ? {
                          step_3_data: {
                              ein_ssn_itin,
                          },
                      }
                    : {}),
                ...(step > 3 ? { step_4_data } : {}),
            });
        },
    },
    persist: true,
});
