import { defineStore } from "pinia";
import { ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useStore } from "@/stores/formStore";
import { dashboardRedirect, validateErrorPage } from "@/util/dashboardRedirect";
import { resetFormData } from "./reset-stores";
import { validateLandingPage } from "@/util/helper";
import { PostHog } from "posthog-js";

export const useAppStore = defineStore("application", () => {
    const isTokenFetching = ref(false);
    const isFormFetching = ref(false);

    const refreshInitData = async (posthogInstance?: PostHog) => {
        const authStore = useAuthStore();

        // force refresh for landing page
        if (!validateErrorPage() && (!authStore.authToken || validateLandingPage())) {
            isTokenFetching.value = true;
            await authStore.refreshToken(undefined, posthogInstance);
            isTokenFetching.value = false;

            if (!authStore.authToken) {
                dashboardRedirect();
            }
        }
    };

    const manageForm = async () => {
        isFormFetching.value = true;
        // force reset form data
        resetFormData();

        const formStore = useStore();
        const pathFormId = new URLSearchParams(window.location.search).get("formId");

        if (!pathFormId) {
            isFormFetching.value = false;

            return;
        }

        await formStore.getLastModifiedForm(pathFormId);

        if (
            !formStore.lastModifiedForm ||
            formStore.isLastModifiedFormLocked() ||
            formStore.formIsRefunded(formStore.lastModifiedForm)
        ) {
            isFormFetching.value = false;
            dashboardRedirect();

            return;
        }

        formStore.saveFormId(formStore.lastModifiedForm?.formId || "");
        await formStore.getLastModifiedStep();
        await formStore.downloadFormData();
        isFormFetching.value = false;
    };

    return {
        isTokenFetching,
        isFormFetching,
        refreshInitData,
        manageForm,
    };
});
