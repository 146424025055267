import { UserProfile } from "./user-profile";

export interface LoginResponse {
    profile: UserProfile;
    token: string;
    token_type: LoginResponseTokenTypeEnum;
}

export const LoginResponseTokenTypeEnum = {
    Jwt: "jwt",
    Redirect: "redirect",
} as const;

export type LoginResponseTokenTypeEnum = (typeof LoginResponseTokenTypeEnum)[keyof typeof LoginResponseTokenTypeEnum];
