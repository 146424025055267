<template>
    <div>
        <app-header :authorized="isAuthorized" />

        <div class="container body-content">
            <RouterView />
        </div>

        <footer class="footer">
            <div class="container">
                <p class="text-muted">
                    &copy; {{ new Date().getFullYear() }} BOIR forms
                    <span v-if="version" class="app-version">v{{ version }}</span>
                </p>
            </div>
        </footer>

        <overlay v-if="isTokenLoading || isFormLoading">
            <div>
                <template v-if="isTokenLoading">
                    <div>Authorization in progress</div>
                </template>
                <template v-else>
                    <div>Loading Form data</div>
                </template>
                <div>Please wait...</div>
            </div>
        </overlay>
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    import { useRouter } from "vue-router";

    import { useToast } from "@/composables/useToast";
    import { useAxios } from "@/plugins/axios";

    import appHeader from "@/components/Header/index.vue";
    import overlay from "@/components/Overlay/index.vue";
    import { useAuthStore } from "./stores/authStore";
    import authService from "@/services/auth-service";
    import { dashboardRedirect } from "@/util/dashboardRedirect";
    import { GeneralServerError } from "@/constants/errors";
    import { useAppStore } from "@/stores/appStore";
    import { usePostHogProvider } from "@/composables/usePosthog";
    import { verifySvcApiGetError } from "@/util/api-error-code";
    import { EditFormIsLocked } from "@/constants/api-error-codes";
    import { getCookiesFromQs } from "@/util/cookies.ts";
    import { getSessionStorageFromQs } from "@/util/sessionStorage.ts";

    const router = useRouter();
    const { errorToast } = useToast();
    const axios = useAxios();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    const posthogInstance = usePostHogProvider();

    const version = import.meta.env.VITE_APP_VERSION;

    initAjax();
    getCookiesFromQs();
    getSessionStorageFromQs();

    function initAjax() {
        axios.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                if (!error.request) {
                    errorToast(`Oops! ${error.message}`);
                }

                return Promise.reject(error);
            },
        );

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.response) {
                    const originalRequest = error.config;
                    let errorMessage = GeneralServerError;

                    if (error.response.status === 400) {
                        // Form is not suitable for edit redirect back to dashboard
                        if (error.response.data?.code === EditFormIsLocked) {
                            dashboardRedirect();
                            return Promise.reject(error);
                        }

                        if (verifySvcApiGetError(error.response.data?.code)) {
                            errorMessage = "";
                        }
                    }

                    if (error.response.status === 401) {
                        if (originalRequest._retry) {
                            dashboardRedirect();
                            return Promise.reject(error);
                        }

                        originalRequest._retry = true;

                        try {
                            const authData = await authService.refreshToken(authStore.refToken);
                            if (!authData.jwt_token) {
                                dashboardRedirect();
                                return Promise.reject(error);
                            }

                            authStore.setAuthData(authData.jwt_token, authData.profile, posthogInstance);
                        } catch (error) {
                            dashboardRedirect();
                            return Promise.reject(error);
                        }

                        originalRequest.headers.Authorization = `Bearer ${authStore.authToken}`;

                        return axios.request(originalRequest);
                    }

                    if (error.response.status === 404) {
                        router.replace({ name: "not-found" });
                    }

                    if (error.response.status === 500) {
                        if (error.response.data.error) {
                            errorMessage = `Oops! ${error.response.data.error}`;
                        } else {
                            errorMessage = `Oops! ${error.message}`;
                        }
                    }

                    if (errorMessage) {
                        errorToast(errorMessage);
                    }
                } else {
                    errorToast("Oops! " + error.message);
                }

                return Promise.reject(error);
            },
        );
    }

    const isAuthorized = computed(() => {
        return authStore.hasAuthToken;
    });

    const isTokenLoading = computed(() => {
        return appStore.isTokenFetching;
    });

    const isFormLoading = computed(() => {
        return appStore.isFormFetching;
    });

    appStore.refreshInitData(posthogInstance);
</script>
