import { post } from "./api-client";
import { LoginRequest } from "@/models/login-request";
import { LoginResponse } from "@/models/login-response";
import { RefreshTokenResponse } from "@/models/refresh-token-response";

const basePath = import.meta.env.VITE_EXT_FORM_API;
function axiosRequestConfig() {
    return {
        withCredentials: true,
    };
}

const service = {
    login: (data: LoginRequest): Promise<LoginResponse> => post(basePath, "/oauth/token", data),

    refreshToken: (data?: string): Promise<RefreshTokenResponse> =>
        data
            ? post(basePath, `/oauth/refresh?refresh-token=${data}`, null)
            : post(basePath, "/oauth/refresh", null, axiosRequestConfig),
};

export default service;
