import { get, post, put, del } from "./api-client";
import { useAuthStore } from "@/stores/authStore";
import type { CompanyDetailsViewModel } from "@/models/company-details";
import type { BusinessTypeViewModel } from "@/models/business-type";
import type { EntityViewModel } from "@/models/entity";
import type { CompanyApplicantViewModel } from "@/models/company-applicant";
import type { CompanyApplicantDocumentViewModel } from "@/models/company-applicant-document";
import type { BeneficialOwnerViewModel } from "@/models/beneficial-owner";
import type { BeneficialOwnerDocumentViewModel } from "@/models/beneficial-owner-document";
import type { Form } from "@/models/svc/form";
import type { ApplicantDocumentImage } from "@/models/applicant-document-image";
import type { FormMetadata } from "@/models/svc/form-metadata";
import type { Applicant } from "@/models/svc/applicant";
import type { IdDocument } from "@/models/svc/id-document";
import type { Filer } from "@/models/svc/filer";
import type { BeneficialOwnerList } from "@/models/svc/beneficial-owner-list";
import type { BaseRequest } from "@/models/base-request";
import type { BeneficialOwner } from "@/models/svc/beneficial-owner";
import type { FormComplete } from "@/models/svc/form-complete";

const basePath = import.meta.env.VITE_BOIR_API;

function axiosRequestConfig(contentType?: string) {
    const headers = {
        Authorization: `Bearer ${useAuthStore().authToken}`,
        "X-Boir-Refresh-Token": useAuthStore().refToken,
    };

    Object.assign(headers, !!contentType && { "Content-Type": contentType });

    return {
        headers,
        withCredentials: true,
    };
}

const service = {
    saveCompanyDetails: (data: CompanyDetailsViewModel): Promise<Form> =>
        post(basePath, "/form/saveCompanyDetails", data, axiosRequestConfig()),

    putCompanyDetails: (data: CompanyDetailsViewModel): Promise<Form> =>
        put(basePath, "/form/putCompanyDetails", data, axiosRequestConfig()),

    saveBusinessType: (data: BusinessTypeViewModel) =>
        post(basePath, "/form/saveBusinessType", data, axiosRequestConfig()),

    saveEntity: (data: EntityViewModel) => post(basePath, "/form/saveEntity", data, axiosRequestConfig()),

    saveCompanyApplicant: (data: CompanyApplicantViewModel, query: string | undefined) =>
        post(basePath, `/form/saveCompanyApplicant${query}`, data, axiosRequestConfig()),

    saveCompanyApplicantDocument: (data: CompanyApplicantDocumentViewModel) =>
        post(basePath, "/form/saveCompanyApplicantDocument", data, axiosRequestConfig()),

    saveApplicantImage: (data: ApplicantDocumentImage) =>
        post(basePath, "/form/saveApplicantImage", data, axiosRequestConfig("multipart/form-data")),

    saveFiler: (data: CompanyApplicantViewModel) => post(basePath, "/form/saveFiler", data, axiosRequestConfig()),

    saveBeneficialOwnerList: (data: BeneficialOwnerViewModel[]): Promise<BeneficialOwner[]> =>
        post(basePath, "/form/saveBeneficialOwnerList", data, axiosRequestConfig()),

    saveBeneficialOwner: (data: BeneficialOwnerViewModel) =>
        post(basePath, "/form/saveBeneficialOwner", data, axiosRequestConfig()),

    editBeneficialOwner: (data: BeneficialOwnerViewModel, id: string) =>
        put(basePath, `/form/editBeneficialOwner?id=${id}`, data, axiosRequestConfig()),

    deleteBeneficialOwner: (formId: string, boId: string) =>
        del(basePath, `/form/deleteBeneficialOwner?formId=${formId}&boId=${boId}`, axiosRequestConfig()),

    saveBeneficialOwnerDocument: (data: BeneficialOwnerDocumentViewModel) =>
        post(basePath, `/form/saveBeneficialOwnerDocument`, data, axiosRequestConfig()),

    saveBeneficialOwnerImage: (data: ApplicantDocumentImage) =>
        post(basePath, `/form/saveBeneficialOwnerImage`, data, axiosRequestConfig("multipart/form-data")),

    saveFormMeta: (data: BaseRequest) => post(basePath, "/form/saveFormMeta", data, axiosRequestConfig()),

    saveForm: (data: BaseRequest): Promise<FormComplete> =>
        post(basePath, "/form/saveForm", data, axiosRequestConfig()),

    getForm: (formId: string): Promise<Form> => get(basePath, `/form/${formId}`, axiosRequestConfig()),

    getFormMeta: (formId: string): Promise<FormMetadata> => get(basePath, `/form/${formId}/meta`, axiosRequestConfig()),

    getCompanyApplicant: (formId: string): Promise<Applicant> =>
        get(basePath, `/form/${formId}/applicant`, axiosRequestConfig()),

    getCompanyApplicantDocument: (formId: string): Promise<IdDocument> =>
        get(basePath, `/form/${formId}/applicant/id-document`, axiosRequestConfig()),

    getFiler: (formId: string): Promise<Filer> => get(basePath, `/form/${formId}/filer`, axiosRequestConfig()),

    getBeneficialOwners: (formId: string): Promise<BeneficialOwnerList> =>
        get(basePath, `/form/${formId}/beneficial-owners`, axiosRequestConfig()),

    getBeneficialOwnerDocument: (formId: string, beneficialOwnerId: string): Promise<IdDocument> =>
        get(basePath, `/form/${formId}/beneficial-owner/${beneficialOwnerId}/id-document`, axiosRequestConfig()),
};

export default service;
